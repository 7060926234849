/* You can add global styles to this file, and also import other style files */

//== Vendor

@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/ionicons/css/ionicons.css';
@import '../node_modules/font-awesome/css/font-awesome.css';

//@import '../node_modules/ika.jvectormap/jquery-jvectormap-1.2.2.css';

@import '../node_modules/summernote/dist/summernote.css';

@import '../node_modules/material-colors/dist/colors.css';

@import '../node_modules/loaders.css/loaders.css';

@import '../node_modules/sweetalert2/dist/sweetalert2.min.css';

@import '../node_modules/ng-material-floating-button/mfb/dist/mfb.css';

@import '../node_modules/blueimp-gallery/css/blueimp-gallery.min.css';

///* ===== Scrollbar CSS ===== */
///* Firefox */
//* {
//  scrollbar-width: auto;
//  scrollbar-color: #6c7076 #ffffff00
//}
//
///* Chrome, Edge, and Safari */
//*::-webkit-scrollbar {
//  width: 14px;
//}
//
//*::-webkit-scrollbar-track {
//  background: #ffffff00;
//}
//
//*::-webkit-scrollbar-thumb {
//  background-color: #6c7076;
//  border-radius: 10px;
//  border: 2px solid #ffffff00;
//}
///* ===== Scrollbar CSS ===== */


.swal2-title {
  font-size: 30px !important;
}

.swal2-content {
  font-size: 18px !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
  font-size: 17px !important;
  padding: 10px 32px !important;
}

.swal2-popup {
  width: 500px !important;
  padding: 20px !important;
}

body {
  display: contents;
}

h3 {
  i {
    float: right;
  }
}

.cursor-pointer:hover {
  cursor: pointer;
}

.ct-label {
  font-size: 14px !important;
}

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #00799a !important;
}

.ct-series-a .ct-area, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-slice-pie {
  fill: #60a7d1 !important;
}

.ct-line {
  fill: none;
  stroke-width: 2px !important;
}

.ct-grid {
  stroke: #cccccc4d !important;
  stroke-width: 1px !important;
  stroke-dasharray: 0 !important;
}

.ct-area {
  fill-opacity: 0.3 !important;
}

.chartist-tooltip {
  margin-left: 15px;
}

.btn-round {
  border-radius: 10px !important;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.pl-10 {
  padding-left: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.p-0 {
  padding: 0 !important;
}

.pb-5 {
  padding-bottom: 5px;
}

.align-middle-center {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  padding: 10px 0;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.radius-50 {
  border-radius: 50% !important;
}

.balls {
  .nsm-dialog {
    right: 0 !important;
    position: absolute !important;
    padding: 0 !important;
    margin-top: 47px !important;
    margin-right: -16px !important;
    width: 400px !important;
    max-width: 100% !important;
    height: calc(100% - 65px) !important;
  }

  .nsm-content {
    height: 100%;
    overflow: scroll;
  }
}

.sidebar-right {
  .nsm-dialog {
    right: 0 !important;
    position: absolute !important;
    padding: 0 !important;
    margin-top: 47px !important;
    margin-right: -16px !important;
    width: 400px !important;
    max-width: 100% !important;
    height: calc(100% - 65px) !important;
  }

  .nsm-content {
    height: 100%;
    overflow: scroll;
  }
}


@media (max-width: 1200px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
}

@media (min-width: 1200px) {
  .desktop-only {
    display: block;
  }
  .mobile-only {
    display: none;
  }
}

.mt-5 {
  margin-top: 5px;
}
.mt-8 {
  margin-top: 8px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-15 {
  margin-left: 15px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 5;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.font-16 {
  font-size: 16px;
}

.slick-prev:before, .slick-next:before {
  color: #4eaf51 !important;
}

.reservation-carousel {
  .slick-prev {
    left: -18px !important;
  }

  .slick-next {
    right: -19px !important;
  }

  .slick-prev:before, .slick-next:before {
    color: #738c98 !important;
  }
}

.pb-0 {padding-bottom: 0;}

.slick-track {
  //transform: initial !important;
  //width: 700px !important;
}

.slick-current {
  //background: yellow !important;
}

.slick-active {
  //background: pink ;

}

.space-around {
  justify-content: space-around;
}

.theme-8 .border-top-based-on-theme,
.theme-9 .border-top-based-on-theme {
    border-top: 1px solid #2F2F2F;
}

.theme-4 .border-top-based-on-theme {
    border-top: 1px solid #fff;
}

.bg-green-500 {
  background: #00d285;
}
.color-green-500 {
  color: #00d285;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mr-10 {
  margin-right: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

